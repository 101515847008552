@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: BMJUA_ttf;
  src: url("../src/assets/fonts/BMJUA_ttf.ttf");
}
@font-face {
  font-family: NanumSquare;
  src: url("../src/assets/fonts/NanumSquareR.otf"),
    url("../src/assets/fonts/NanumSquareR.ttf");
}
@font-face {
  font-family: umeboshi;
  src: url('../src/assets/fonts/umeboshi_.ttf');
}

html {
  --color-blue-book-detail: rgb(18, 83, 137);
}

* {
  box-sizing: border-box;
  font-family: "BMJUA_ttf", serif, "serif";
}

html,
body {
  padding: 0px;
  margin: 0px;
}

body {
  color: white;
  margin: 0;
  background: url("../src/assets/images/background/background.png") no-repeat
    center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (min-width: 1025px) {
  body {
    background: url("../src/assets/images/background/background_desktop.png")
      no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
