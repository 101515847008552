.login-border {
  background-image: url("../../src/assets/images/borders/login_line.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.login-btn-shadow {
  box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.5);
}

.icon-err {
  position: relative;
  animation: bounce 0.7s linear 0s infinite alternate;
  -webkit-animation: bounce 0.5s linear 0s infinite alternate;
  -moz-animation: bounce 0.5s linear 0s infinite alternate;
  -ms-animation: bounce 0.5s linear 0s infinite alternate;
  -o-animation: bounce 0.5s linear 0s infinite alternate;
}

@-webkit-keyframes bounce {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 5px;
  }
}
@-moz-keyframes bounce {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 5px;
  }
}
@-ms-keyframes bounce {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 5px;
  }
}
@-o-keyframes bounce {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 5px;
  }
}

@keyframes bounce {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 5px;
  }
}
