

/* book img */
.book-detail-shadow {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.8);
}

/* scrrolbar */

fieldset::-webkit-scrollbar {
  width: 9px;
}

fieldset::-webkit-scrollbar-thumb {
  background-color: var(--color-blue-book-detail);
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

fieldset::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  /* box-shadow: inset 0px 0px 5px white; */
}

@media (min-width:821px) {
  .description{
    line-height: 1.34rem;
    font-size: 1rem;
    letter-spacing: 0.12rem;
  }  
}
