/* book img */

.book-item {
  margin: 20px auto 0;
  -moz-transform: scaleX(0.88) scaleY(0.95);
  -moz-perspective: 150px;
  -moz-perspective-origin: right center;

  -webkit-perspective: 150px;
  -webkit-perspective-origin: right center;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: scaleX(0.88) scaleY(0.95);

  -o-perspective: 150px;
  -o-perspective-origin: right center;
  -o-transform-style: preserve-3d;
  -o-transform: scaleX(0.88) scaleY(0.95);

  -ms-perspective: 150px;
  -ms-perspective-origin: right center;
  -ms-transform-style: preserve-3d;
  -ms-transform: scaleX(0.88) scaleY(0.95);

  perspective: 150px;
  perspective-origin: right center;
  transform-style: preserve-3d;
  transform: scaleX(0.88) scaleY(0.95);
}
.book-item img {
  display: block;
  position: absolute;
  z-index: 5;
  -moz-transform: rotateY(-13deg) translateZ(0px);
  -webkit-transform: rotateY(-13deg) translateZ(0px);
  -o-transform: rotateY(-13deg) translateZ(0px);
  -ms-transform: rotateY(-13deg) translateZ(0px);
  transform: rotateY(-13deg) translateZ(0px);
}

.book-item:before,
.book-item:after {
  content: "";
  position: absolute;
}

/* bookcover */
.book-item:before {
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0%;
  background: rgba(43, 49, 60, 0.8);
  z-index: 2;
  -moz-transform: rotateY(-4deg) translateZ(0px);
  -webkit-transform: rotateY(-4deg) translateZ(0px);
  -o-transform: rotateY(-4deg) translateZ(0px);
  -ms-transform: rotateY(-4deg) translateZ(0px);
  transform: rotateY(-4deg) translateZ(0px);
}

/* book inner */
.book-item:after {
  background: #f5f5f5;
  box-shadow: inset 1px 13px 23px rgba(0, 0, 0, 0.09);
  height: 97%;
  top: 2%;
  z-index: 3;
  -moz-perspective: 5px;
  -moz-transform: rotateY(20deg) translateZ(5px);
  -webkit-perspective: 5px;
  -webkit-transform: rotateY(20deg) translateZ(5px);
  -o-perspective: 5px;
  -o-transform: rotateY(20deg) translateZ(5px);
  -ms-perspective: 5px;
  -ms-transform: rotateY(20deg) translateZ(5px);
  perspective: 5px;
  transform: rotateY(20deg) translateZ(5px);
}

.book-item img:hover {
  transform: rotateY(0) translateZ(0px);
  -moz-transform: rotateY(0) translateZ(0px);
  -webkit-transform: rotateY(0) translateZ(0px);
  -o-transform: rotateY(0) translateZ(0px);
  -ms-transform: rotateY(0) translateZ(0px);
  transition: all 0.1s ease-in-out;
}

.prb-wrapper::-webkit-scrollbar {
  width: 9px;
}

.prb-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-blue-book-detail);
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.prb-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}
