/* loginform */
.login-input {
  box-shadow: inset 1px 5px 5px rgb(0, 97, 186);
}

.login-input::placeholder {
  color: rgb(163, 193, 243);
  font-family: "BMJUA_ttf";
}
/* 
.login-btn-shadow {
  box-shadow: inset 2px -3px 8px rgba(0, 0, 0, 0.3);
} */
